<template>
  <!--
    The view for the Employee Worklog Report generation
  -->
  <EmployeeWorklogReportPdf
    v-if="pdf == 'true'"
    :s-start-date="sStartDate"
    :s-end-date="sEndDate"
  />
  <EmployeeWorklogReport
    v-else
    :s-start-date="sStartDate"
    :s-end-date="sEndDate"
  />
</template>

<script>
export default {
  name: "EmployeeWorklogReportView",
  components: {
    EmployeeWorklogReport: () => import('@/components/CustomerWorklog/EmployeeWorklogReport.vue'),
    EmployeeWorklogReportPdf: () => import('@/components/CustomerWorklog/EmployeeWorklogReportPdf.vue'),
  },
  props: {
    sStartDate: {
      type: String,
      required: false,
      default: null
    },
    sEndDate: {
      type: String,
      required: false,
      default: null
    },
    pdf: {
      type: String,
      required: false,
      default: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('employeeWorklogReport')
    }
  }
}
</script>
